import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm';
import { Link } from 'gatsby';
import { Layout } from '../../layouts/Layout';
import { SEO } from '../../components/SEO';
export const coloredContainer = {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  height: '200px',
  width: '200px'
};
export const darkColoredContainer = { ...coloredContainer,
  backgroundColor: 'black',
  marginRight: '50px'
};
export const divStyles = {
  padding: '22px 0 42px'
};
export const lightColoredContainer = { ...coloredContainer,
  backgroundColor: COLORS.PRIMARY_SAND
};
export const _frontmatter = {};
const layoutProps = {
  coloredContainer,
  darkColoredContainer,
  divStyles,
  lightColoredContainer,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEO title="Identity > Logotypes" mdxType="SEO" />
    <h1>{`Logotypes`}</h1>
    <p>{`⚠️ These are merely examples of how our logo is used on both light and dark colored backgrounds.`}</p>
    <p>{`Do `}<strong parentName="p">{`NOT`}</strong>{` use them as-is, since the background is not part of the logo itself.`}</p>
    <p>{`If you need assets, please refer to our `}<a parentName="p" {...{
        "href": "https://ulule.frontify.com/"
      }}>{`style guide`}</a>{`.`}</p>
    <br />
    <h2>{`Square`}</h2>
    <div style={divStyles}>
  <span style={darkColoredContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 200" width="120px">
      <defs>
        <path id="a" d="M.402.693h142.5v142.499H.402z" />
        <path id="c" d="M0 .33h36.204v33.837H0z" />
        <path id="e" d="M.178.023h36.205V40H.178z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(13.333 .14)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path d="M71.652 143.192c-39.287 0-71.25-31.962-71.25-71.25V36.05c0-2.287.89-4.437 2.508-6.055a8.506 8.506 0 016.054-2.508c2.03 0 3.952.702 5.489 1.99C27.883 11.434 49.19.692 71.653.692c22.46 0 43.77 10.742 57.197 28.785a8.504 8.504 0 015.49-1.99c2.286 0 4.436.89 6.053 2.507a8.517 8.517 0 012.51 6.056v35.892c0 39.288-31.963 71.25-71.25 71.25" fill="#FFF" mask="url(#b)" />
        </g>
        <path d="M149.603 34.26a2.724 2.724 0 00-1.93-.799c-.7 0-1.396.267-1.93.8l-4.48 4.479c-11.4-19.199-32.33-32.074-56.278-32.074-23.947 0-44.879 12.875-56.278 32.074l-4.48-4.48a2.726 2.726 0 00-4.658 1.93v35.893c0 36.128 29.287 65.417 65.416 65.417s65.417-29.289 65.417-65.417V36.19c0-.697-.267-1.396-.8-1.93" fill="#000" />
        <path d="M113.207 108.505c-11.633 0-21.722-6.594-26.744-16.247a1.658 1.658 0 00-2.955 0c-5.022 9.653-15.112 16.247-26.745 16.247-17.357 0-31.287-14.675-30.057-32.295C27.735 61.475 39.588 49.5 54.312 48.337c12.907-1.02 24.264 6.103 29.504 16.773-2.309 2.635-3.72 6.075-3.72 9.854 0 4.152 1.697 7.899 4.424 10.614a.665.665 0 00.93 0c2.728-2.715 4.426-6.462 4.426-10.614 0-3.78-1.412-7.219-3.721-9.854 5.24-10.67 16.597-17.793 29.503-16.773 14.725 1.164 26.578 13.138 27.606 27.873 1.23 17.62-12.7 32.295-30.057 32.295" fill="#FFF" />
        <path d="M55.69 54.886c6.572.632 11.736 6.15 11.78 12.89.047 7.202-5.752 13.082-12.954 13.131-7.204.05-13.083-5.748-13.13-12.951-.047-6.8 5.127-12.391 11.762-13.039.001-.007.003-.013.003-.02-9.998.661-17.877 8.99-17.81 19.143.072 10.542 8.678 19.029 19.216 18.957 10.543-.071 19.026-8.672 18.958-19.218-.067-10.067-7.933-18.246-17.826-18.893" fill="#18A5D6" />
        <path d="M57.275 57.242c1.146 1.086 1.904 2.168 1.893 3.937-.01 2.549-2.167 4.62-4.808 4.632-2.522-.017-4.652-2.157-4.667-4.674-.012-2.057 1.177-3.2 1.817-3.812l.281-.274c.722-.695 1.29-1.25 1.357-2.134-6.635.648-11.81 6.24-11.76 13.038.045 7.204 5.924 13.002 13.127 12.952 7.204-.05 13.002-5.928 12.955-13.13-.044-6.742-5.209-12.26-11.78-12.89-.035-.003-.07-.003-.105-.005.069.785.89 1.632 1.69 2.36" fill="#18A5D6" />
        <path d="M57.275 57.242c1.146 1.086 1.904 2.168 1.893 3.937-.01 2.549-2.167 4.62-4.808 4.632-2.522-.017-4.652-2.157-4.667-4.674-.012-2.057 1.177-3.2 1.817-3.812l.281-.274c.722-.695 1.29-1.25 1.357-2.134-6.635.648-11.81 6.24-11.76 13.038.045 7.204 5.924 13.002 13.127 12.952 7.204-.05 13.002-5.928 12.955-13.13-.044-6.742-5.209-12.26-11.78-12.89-.035-.003-.07-.003-.105-.005.069.785.89 1.632 1.69 2.36" fill="#004E69" />
        <path d="M116.7 54.888l.002.017c6.693.59 11.93 6.21 11.881 13.05-.046 7.204-5.925 13.002-13.129 12.952-7.204-.049-13-5.929-12.955-13.13.045-6.736 5.201-12.249 11.765-12.888h.001c-9.885.653-17.742 8.83-17.81 18.89-.07 10.546 8.414 19.147 18.958 19.218 10.538.072 19.144-8.415 19.215-18.957.068-10.194-7.875-18.549-17.929-19.152" fill="#F01446" />
        <path d="M118.389 57.242c1.146 1.085 1.901 2.167 1.891 3.936-.01 2.55-2.165 4.622-4.808 4.634-2.522-.018-4.65-2.158-4.667-4.675-.01-2.057 1.178-3.199 1.817-3.811l.283-.275c.728-.703 1.304-1.261 1.36-2.162-6.565.638-11.721 6.153-11.765 12.888-.046 7.201 5.75 13.08 12.955 13.13 7.203.05 13.082-5.748 13.128-12.951.05-6.84-5.188-12.461-11.88-13.052.08.78.892 1.617 1.686 2.338" fill="#F01446" />
        <path d="M118.389 57.242c1.146 1.085 1.901 2.167 1.891 3.936-.01 2.55-2.165 4.622-4.808 4.634-2.522-.018-4.65-2.158-4.667-4.675-.01-2.057 1.178-3.199 1.817-3.811l.283-.275c.728-.703 1.304-1.261 1.36-2.162-6.565.638-11.721 6.153-11.765 12.888-.046 7.201 5.75 13.08 12.955 13.13 7.203.05 13.082-5.748 13.128-12.951.05-6.84-5.188-12.461-11.88-13.052.08.78.892 1.617 1.686 2.338" fill="#8B0020" />
        <path d="M114.28 54.887h-.015l-.001.002.017-.002" fill="#FFF" />
        <g transform="translate(0 165.14)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path d="M25.077 15.546c0 5.786-2.82 8.234-6.975 8.234-4.155 0-6.975-2.448-6.975-8.234V3.597c0-.63-.539-1.125-1.166-1.069L.98 3.313c-.554.05-.98.513-.98 1.07v11.163c0 12.389 7.27 18.621 18.102 18.621 10.831 0 18.102-6.306 18.102-18.621V1.403c0-.63-.54-1.124-1.167-1.07l-8.98.786c-.555.05-.98.513-.98 1.07v13.357z" fill="#FFF" mask="url(#d)" />
        </g>
        <g transform="translate(70 159.308)">
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path d="M25.255 21.379c0 5.787-2.82 8.234-6.974 8.234-4.155 0-6.975-2.447-6.975-8.234V3.29c0-.63-.54-1.125-1.167-1.07l-8.98.785c-.555.05-.98.514-.98 1.07V21.38C.178 33.769 7.448 40 18.28 40c10.831 0 18.102-6.307 18.102-18.622V1.096c0-.63-.54-1.123-1.167-1.069l-8.981.786c-.554.048-.98.513-.98 1.07v19.496z" fill="#FFF" mask="url(#f)" />
        </g>
        <path d="M113.353 187.213v10.056c0 .592.48 1.073 1.074 1.073h20.45c.593 0 1.074-.48 1.074-1.073v-8.982c0-.593-.48-1.074-1.074-1.074h-10.395v-28.392c0-.63-.54-1.125-1.167-1.07l-8.981.786c-.555.049-.981.513-.981 1.07v27.606zM43.175 187.213v10.056c0 .592.48 1.073 1.074 1.073h20.45c.593 0 1.074-.48 1.074-1.073v-8.982c0-.593-.48-1.074-1.074-1.074H54.304v-22.252c0-.63-.54-1.125-1.167-1.07l-8.982.786c-.555.049-.98.513-.98 1.07v21.466zM169.97 163.861v-9.02c0-.63-.538-1.124-1.166-1.07l-25.485 2.23c-.554.048-.98.513-.98 1.07v40.198c0 .593.48 1.074 1.073 1.074h25.485c.593 0 1.074-.481 1.074-1.074v-8.981c0-.594-.481-1.075-1.074-1.075h-15.43v-6.04h9.385c.593 0 1.074-.48 1.074-1.073v-7.5c0-.592-.481-1.073-1.074-1.073h-9.385v-5.238l15.524-1.359c.554-.048.98-.512.98-1.069" fill="#FFF" />
      </g>
    </svg>
  </span>
  <span style={lightColoredContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176 200" width="120px">
      <defs>
        <path id="a" d="M.414.713h134.764v134.764H.414z" />
        <path id="c" d="M0 .34h37.292v34.853H0z" />
        <path id="e" d="M.183.024h37.293v41.178H.183z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M116.608 105.757c-11.983 0-22.375-6.793-27.547-16.736a1.707 1.707 0 00-3.044 0c-5.173 9.943-15.566 16.736-27.549 16.736-17.879 0-32.226-15.117-30.96-33.266 1.06-15.178 13.27-27.511 28.436-28.71 13.294-1.051 24.992 6.286 30.39 17.277-2.378 2.714-3.832 6.257-3.832 10.15 0 4.277 1.748 8.136 4.557 10.932.262.261.697.261.959 0 2.81-2.796 4.558-6.655 4.558-10.933 0-3.892-1.454-7.435-3.833-10.15 5.398-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.376 13.532 28.435 28.71 1.268 18.149-13.08 33.266-30.96 33.266" fill="#FFF" />
        <g transform="translate(19.742 .145)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 00-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932.262.261.697.261.96 0 2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266m37.49-76.476a2.808 2.808 0 00-3.975 0l-4.615 4.614C114.023 13.975 92.463.713 67.796.713S21.57 13.975 9.827 33.75l-4.614-4.614a2.802 2.802 0 00-1.988-.823 2.806 2.806 0 00-2.81 2.811v36.97c0 37.215 30.167 67.383 67.381 67.383s67.382-30.168 67.382-67.382V31.124c0-.718-.275-1.439-.823-1.988" fill="#000" mask="url(#b)" />
        </g>
        <path d="M57.362 50.527c6.77.65 12.09 6.334 12.135 13.277.048 7.418-5.924 13.475-13.343 13.525-7.42.051-13.477-5.92-13.524-13.34-.05-7.004 5.28-12.764 12.115-13.43.001-.008.003-.014.003-.022-10.298.682-18.414 9.261-18.344 19.719.073 10.859 8.938 19.6 19.792 19.527 10.86-.074 19.598-8.933 19.528-19.796-.07-10.37-8.172-18.794-18.362-19.46" fill="#18A5D6" />
        <path d="M58.996 52.953c1.18 1.119 1.96 2.233 1.95 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.598-.017-4.792-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.871-3.926l.29-.282c.744-.716 1.329-1.288 1.398-2.199-6.835.667-12.165 6.428-12.115 13.43.048 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.045-6.945-5.365-12.628-12.134-13.278-.036-.003-.072-.003-.108-.005.07.809.916 1.68 1.741 2.431" fill="#18A5D6" />
        <path d="M58.996 52.953c1.18 1.119 1.96 2.233 1.95 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.598-.017-4.792-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.871-3.926l.29-.282c.744-.716 1.329-1.288 1.398-2.199-6.835.667-12.165 6.428-12.115 13.43.048 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.045-6.945-5.365-12.628-12.134-13.278-.036-.003-.072-.003-.108-.005.07.809.916 1.68 1.741 2.431" fill="#004E69" />
        <path d="M120.205 50.528l.003.018c6.894.608 12.288 6.397 12.238 13.443-.048 7.42-6.103 13.392-13.523 13.34-7.42-.05-13.392-6.107-13.344-13.525.046-6.937 5.357-12.616 12.118-13.275-10.181.673-18.275 9.095-18.345 19.458-.07 10.863 8.668 19.722 19.529 19.796 10.854.073 19.718-8.668 19.792-19.527.07-10.5-8.112-19.106-18.468-19.728" fill="#F01446" />
        <path d="M121.945 52.953c1.18 1.118 1.96 2.232 1.949 4.055-.01 2.625-2.23 4.76-4.953 4.772-2.597-.018-4.79-2.222-4.807-4.814-.01-2.12 1.213-3.296 1.872-3.927l.291-.283c.75-.723 1.343-1.299 1.4-2.226-6.76.657-12.073 6.337-12.118 13.274-.047 7.418 5.924 13.474 13.344 13.526 7.42.05 13.476-5.922 13.523-13.341.05-7.046-5.343-12.835-12.238-13.444.083.803.92 1.665 1.737 2.408" fill="#F01446" />
        <path d="M121.945 52.953c1.18 1.118 1.96 2.232 1.949 4.055-.01 2.625-2.23 4.76-4.953 4.772-2.597-.018-4.79-2.222-4.807-4.814-.01-2.12 1.213-3.296 1.872-3.927l.291-.283c.75-.723 1.343-1.299 1.4-2.226-6.76.657-12.073 6.337-12.118 13.274-.047 7.418 5.924 13.474 13.344 13.526 7.42.05 13.476-5.922 13.523-13.341.05-7.046-5.343-12.835-12.238-13.444.083.803.92 1.665 1.737 2.408" fill="#8B0020" />
        <path d="M117.714 50.527l-.016.001h-.001.017" fill="#FFF" />
        <g transform="translate(0 164.094)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path d="M25.83 16.013c0 5.96-2.905 8.481-7.184 8.481-4.28 0-7.184-2.52-7.184-8.481V3.705c0-.649-.556-1.158-1.202-1.101l-9.25.809C.438 3.463 0 3.94 0 4.513v11.5c0 12.761 7.49 19.18 18.646 19.18s18.646-6.495 18.646-19.18V1.445c0-.649-.556-1.158-1.203-1.101l-9.25.809c-.571.05-1.009.528-1.009 1.103v13.757z" fill="#000" mask="url(#d)" />
        </g>
        <g transform="translate(72.103 158.085)">
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path d="M26.013 22.021c0 5.96-2.904 8.482-7.182 8.482-4.281 0-7.185-2.521-7.185-8.482V3.39c0-.648-.556-1.157-1.202-1.1l-9.251.808c-.57.05-1.01.528-1.01 1.102V22.02c0 12.762 7.49 19.181 18.648 19.181 11.156 0 18.645-6.496 18.645-19.18V1.128c0-.649-.556-1.157-1.202-1.1l-9.251.809c-.57.05-1.01.528-1.01 1.1v20.083z" fill="#000" mask="url(#f)" />
        </g>
        <path d="M116.758 186.829v10.358c0 .61.496 1.105 1.107 1.105h21.064c.611 0 1.107-.495 1.107-1.105v-9.252c0-.61-.496-1.106-1.107-1.106h-10.707v-29.245c0-.649-.555-1.159-1.202-1.102l-9.251.81c-.572.05-1.01.527-1.01 1.1v28.437zM44.472 186.829v10.358c0 .61.495 1.105 1.106 1.105H66.643c.61 0 1.106-.495 1.106-1.105v-9.252c0-.61-.495-1.106-1.106-1.106H55.935v-22.921c0-.649-.555-1.159-1.201-1.101l-9.253.81c-.57.049-1.01.527-1.01 1.1v22.112zM175.077 162.775v-9.29c0-.65-.555-1.159-1.202-1.102l-26.25 2.296c-.571.05-1.01.529-1.01 1.102v41.407c0 .61.495 1.105 1.106 1.105H173.972c.61 0 1.105-.495 1.105-1.106v-9.251c0-.611-.495-1.108-1.105-1.108h-15.894v-6.22h9.667c.61 0 1.106-.495 1.106-1.106v-7.725c0-.61-.496-1.106-1.106-1.106h-9.667v-5.395l15.99-1.4c.57-.05 1.01-.527 1.01-1.1" fill="#000" />
      </g>
    </svg>
  </span>
    </div>
    <h2>{`Line`}</h2>
    <div style={divStyles}>
  <span style={darkColoredContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301 100" height="50px">
      <defs>
        <path id="a" d="M0 0h99.972v100H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M144.449 55.504c0 6.233-3.036 8.868-7.51 8.868-4.473 0-7.51-2.635-7.51-8.868V42.635c0-.678-.58-1.21-1.256-1.152l-9.67.847a1.157 1.157 0 00-1.055 1.152v12.022c0 13.344 7.829 20.056 19.492 20.056 11.662 0 19.49-6.791 19.49-20.056V40.273c0-.68-.582-1.211-1.257-1.152l-9.669.846a1.156 1.156 0 00-1.055 1.152v14.385zM220.012 55.504c0 6.233-3.036 8.868-7.51 8.868-4.473 0-7.51-2.635-7.51-8.868V36.023c0-.679-.581-1.212-1.256-1.152l-9.67.846a1.156 1.156 0 00-1.055 1.152v18.635c0 13.344 7.829 20.056 19.492 20.056 11.662 0 19.49-6.791 19.49-20.056V33.66c0-.678-.58-1.211-1.257-1.152l-9.669.846a1.156 1.156 0 00-1.055 1.152v20.998zM239.499 62.534v10.83c0 .638.518 1.156 1.156 1.156h22.02c.638 0 1.156-.518 1.156-1.156v-9.673c0-.639-.518-1.157-1.156-1.157h-11.193v-30.58c0-.678-.581-1.21-1.257-1.152l-9.671.847a1.156 1.156 0 00-1.055 1.152v29.733zM163.936 62.534v10.83c0 .638.517 1.156 1.156 1.156h22.02c.638 0 1.156-.518 1.156-1.156v-9.673c0-.639-.518-1.157-1.156-1.157h-11.193V38.567c0-.679-.581-1.21-1.257-1.152l-9.67.846a1.156 1.156 0 00-1.056 1.152v23.12zM300.46 37.383v-9.715c0-.678-.58-1.211-1.256-1.152l-27.44 2.402a1.157 1.157 0 00-1.056 1.152v43.294c0 .638.518 1.157 1.156 1.157H299.304c.639 0 1.156-.519 1.156-1.157V63.69c0-.638-.517-1.156-1.156-1.156H282.69v-6.505h10.105c.638 0 1.156-.517 1.156-1.156v-8.078c0-.638-.518-1.156-1.156-1.156H282.69v-5.642l16.714-1.462a1.156 1.156 0 001.055-1.152" fill="#FFF" />
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path d="M49.986 100C22.423 100 0 77.571 0 50V24.812c0-1.604.625-3.114 1.76-4.249a5.968 5.968 0 014.246-1.76A5.96 5.96 0 019.857 20.2C19.28 7.538 34.228 0 49.986 0c15.757 0 30.707 7.538 40.128 20.2a5.965 5.965 0 013.851-1.396c1.604 0 3.112.624 4.246 1.758a5.978 5.978 0 011.76 4.25V50c0 27.571-22.423 50-49.985 50" fill="#FFF" mask="url(#b)" />
        </g>
        <path d="M95.318 23.458a1.908 1.908 0 00-1.353-.56c-.491 0-.98.186-1.353.56l-3.144 3.144C81.471 13.128 66.786 4.094 49.986 4.094S18.5 13.128 10.504 26.602L7.36 23.458a1.904 1.904 0 00-1.353-.56 1.91 1.91 0 00-1.915 1.915v25.187c0 25.354 20.547 45.907 45.894 45.907 25.346 0 45.893-20.553 45.893-45.907V24.812c0-.489-.186-.98-.56-1.354" fill="#000" />
        <path d="M69.785 75.56c-8.161 0-15.24-4.628-18.762-11.402a1.163 1.163 0 00-2.074 0C45.427 70.932 38.348 75.56 30.187 75.56 18.009 75.56 8.237 65.26 9.1 52.896c.722-10.34 9.037-18.743 19.367-19.56 9.054-.715 17.022 4.283 20.698 11.771a10.475 10.475 0 00.494 14.363.466.466 0 00.653 0 10.475 10.475 0 00.494-14.363c3.677-7.488 11.644-12.486 20.699-11.77 10.33.816 18.645 9.218 19.367 19.559.863 12.365-8.91 22.664-21.087 22.664" fill="#FFF" />
        <path d="M29.433 37.933c4.611.442 8.234 4.314 8.265 9.045.033 5.054-4.035 9.18-9.088 9.215a9.148 9.148 0 01-9.211-9.09c-.034-4.77 3.596-8.695 8.251-9.149l.002-.015c-7.014.465-12.541 6.31-12.494 13.434.05 7.399 6.088 13.354 13.48 13.303 7.397-.05 13.349-6.086 13.301-13.486-.048-7.064-5.566-12.804-12.506-13.257" fill="#18A5D6" />
        <path d="M30.546 39.585c.804.762 1.335 1.521 1.327 2.763-.006 1.789-1.52 3.243-3.373 3.25-1.769-.011-3.263-1.513-3.274-3.28-.008-1.443.826-2.244 1.275-2.674l.197-.193c.507-.487.906-.877.953-1.497-4.656.454-8.286 4.379-8.252 9.15a9.146 9.146 0 009.21 9.088c5.055-.034 9.122-4.16 9.09-9.214-.032-4.73-3.655-8.603-8.266-9.045-.024-.003-.048-.003-.073-.004.048.551.624 1.146 1.186 1.656" fill="#18A5D6" />
        <path d="M30.546 39.585c.804.762 1.335 1.521 1.327 2.763-.006 1.789-1.52 3.243-3.373 3.25-1.769-.011-3.263-1.513-3.274-3.28-.008-1.443.826-2.244 1.275-2.674l.197-.193c.507-.487.906-.877.953-1.497-4.656.454-8.286 4.379-8.252 9.15a9.146 9.146 0 009.21 9.088c5.055-.034 9.122-4.16 9.09-9.214-.032-4.73-3.655-8.603-8.266-9.045-.024-.003-.048-.003-.073-.004.048.551.624 1.146 1.186 1.656" fill="#004E69" />
        <path d="M72.235 37.933l.002.012c4.695.415 8.37 4.36 8.335 9.16a9.147 9.147 0 01-9.21 9.087c-5.054-.034-9.121-4.16-9.09-9.214.032-4.726 3.65-8.595 8.255-9.044-6.935.458-12.447 6.196-12.495 13.256-.047 7.4 5.903 13.436 13.3 13.487 7.393.05 13.43-5.906 13.481-13.303.048-7.154-5.525-13.017-12.578-13.44" fill="#F01446" />
        <path d="M73.42 39.585c.804.762 1.335 1.521 1.327 2.763-.007 1.788-1.52 3.243-3.373 3.251-1.77-.012-3.263-1.514-3.274-3.28-.008-1.444.827-2.245 1.274-2.675l.199-.193c.51-.493.915-.885.954-1.517-4.605.448-8.223 4.318-8.254 9.044-.032 5.054 4.034 9.18 9.089 9.215a9.147 9.147 0 009.21-9.09c.034-4.8-3.64-8.744-8.335-9.158.057.546.626 1.134 1.183 1.64" fill="#F01446" />
        <path d="M73.42 39.585c.804.762 1.335 1.521 1.327 2.763-.007 1.788-1.52 3.243-3.373 3.251-1.77-.012-3.263-1.514-3.274-3.28-.008-1.444.827-2.245 1.274-2.675l.199-.193c.51-.493.915-.885.954-1.517-4.605.448-8.223 4.318-8.254 9.044-.032 5.054 4.034 9.18 9.089 9.215a9.147 9.147 0 009.21-9.09c.034-4.8-3.64-8.744-8.335-9.158.057.546.626 1.134 1.183 1.64" fill="#8B0020" />
        <path d="M70.538 37.933h-.011.011" fill="#FFF" />
      </g>
    </svg>
  </span>
  <span style={lightColoredContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 506.93 157" height="50px">
      <path d="M112.37 122.21a36.15 36.15 0 01-32.09-19.5 2 2 0 00-3.55 0 36.17 36.17 0 11.37-32.58 17.91 17.91 0 00.84 24.56.8.8 0 001.12 0 17.91 17.91 0 00.84-24.56 36.16 36.16 0 1132.46 52.07z" fill="#fff" />
      <path d="M156 33.11a3.27 3.27 0 00-4.63 0L146 38.49a78.49 78.49 0 00-135 0l-5.41-5.38A3.27 3.27 0 000 35.43V78.5a78.5 78.5 0 00157 0V35.43a3.27 3.27 0 00-1-2.32zm-43.67 89.09a36.15 36.15 0 01-32.09-19.5 2 2 0 00-3.55 0 36.17 36.17 0 11.37-32.58 17.91 17.91 0 00.84 24.56.8.8 0 001.12 0 17.91 17.91 0 00.84-24.56 36.16 36.16 0 1132.46 52.07z" fill="#000" />
      <path d="M43.34 57.86a15.83 15.83 0 11-3 0 22.83 22.83 0 103 0z" fill="#18a5d6" />
      <path d="M45.25 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33a4.05 4.05 0 001.63-2.61 15.47 15.47 0 103 0h-.12c.12.9 1.11 1.92 2.07 2.79z" fill="#18a5d6" />
      <path d="M45.25 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33a4.05 4.05 0 001.63-2.61 15.47 15.47 0 103 0h-.12c.12.9 1.11 1.92 2.07 2.79z" fill="#004e69" />
      <path d="M116.56 57.87a15.48 15.48 0 11-2.92 0 23 23 0 102.92 0z" fill="#f01446" />
      <path d="M118.58 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33c.87-.84 1.56-1.51 1.63-2.59a15.82 15.82 0 102.92 0c.11.9 1.08 1.91 2.03 2.77z" fill="#f01446" />
      <path d="M118.58 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33c.87-.84 1.56-1.51 1.63-2.59a15.82 15.82 0 102.92 0c.11.9 1.08 1.91 2.03 2.77z" fill="#8b0020" />
      <path d="M113.65 57.86z" fill="#fff" />
      <path d="M240.08 87.91c0 10.66-5.19 15.17-12.84 15.17s-12.85-4.51-12.85-15.17v-22a2 2 0 00-2.15-2l-16.54 1.47a2 2 0 00-1.81 2v20.53c0 22.82 13.39 34.3 33.34 34.3s33.34-11.61 33.34-34.3v-26a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2zM369.33 87.91c0 10.66-5.19 15.17-12.84 15.17s-12.85-4.51-12.85-15.17V54.6a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2v31.86c0 22.82 13.39 34.3 33.34 34.3s33.34-11.61 33.34-34.3V50.56a2 2 0 00-2.15-2L371.13 50a2 2 0 00-1.81 2zM402.66 99.93v18.52a2 2 0 002 2h37.64a2 2 0 002-2v-16.54a2 2 0 00-2-2h-19.14V47.64a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2zM273.41 99.93v18.52a2 2 0 002 2h37.66a2 2 0 002-2v-16.54a2 2 0 00-2-2h-19.16v-41a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2zM506.93 56.92V40.31a2 2 0 00-2.15-2l-46.94 4.11a2 2 0 00-1.81 2v74a2 2 0 002 2H505a2 2 0 002-2v-16.51a2 2 0 00-2-2h-28.46v-11.1h17.28a2 2 0 002-2V73a2 2 0 00-2-2h-17.28v-9.61l28.59-2.5a2 2 0 001.8-1.97z" fill="#000" />
    </svg>
  </span>
    </div>
    <h2>{`Owl only`}</h2>
    <div style={divStyles}>
  <span style={darkColoredContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" height="120" width="120">
      <defs>
        <path id="a" d="M0 0h119.966v120H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path d="M59.983 120C26.908 120 0 93.085 0 60V29.774c0-1.925.75-3.736 2.11-5.098a7.162 7.162 0 015.098-2.112c1.709 0 3.327.591 4.62 1.676C23.135 9.046 41.075 0 59.984 0c18.91 0 36.849 9.046 48.153 24.24a7.158 7.158 0 014.622-1.676 7.16 7.16 0 015.096 2.11 7.173 7.173 0 012.112 5.1V60c0 33.085-26.908 60-59.983 60" fill="#FFF" mask="url(#b)" />
        </g>
        <path d="M114.382 28.15a2.29 2.29 0 00-1.624-.673c-.59 0-1.175.224-1.624.673l-3.772 3.772c-9.597-16.168-27.219-27.01-47.38-27.01-20.16 0-37.781 10.842-47.378 27.01L8.832 28.15a2.284 2.284 0 00-1.624-.673 2.29 2.29 0 00-1.624.673 2.292 2.292 0 00-.674 1.624V60c0 30.424 24.657 55.088 55.073 55.088S115.055 90.424 115.055 60V29.774c0-.586-.224-1.175-.673-1.624" fill="#000" />
        <path d="M83.742 90.672c-9.793 0-18.288-5.553-22.515-13.682a1.396 1.396 0 00-2.488 0c-4.227 8.129-12.721 13.682-22.515 13.682-14.613 0-26.34-12.359-25.304-27.197.866-12.409 10.844-22.492 23.24-23.471 10.866-.86 20.427 5.139 24.839 14.125a12.57 12.57 0 00.593 17.235.56.56 0 00.783 0 12.57 12.57 0 003.725-8.938c0-3.182-1.188-6.078-3.132-8.297 4.411-8.986 13.972-14.984 24.838-14.125 12.395.98 22.374 11.062 23.24 23.47 1.036 14.84-10.69 27.198-25.304 27.198" fill="#FFF" />
        <path d="M35.32 45.519c5.533.531 9.88 5.178 9.918 10.855.039 6.064-4.842 11.015-10.906 11.057-6.064.042-11.014-4.84-11.053-10.906-.04-5.726 4.315-10.435 9.902-10.98l.002-.018c-8.417.558-15.05 7.571-14.993 16.12.06 8.879 7.305 16.025 16.176 15.965 8.876-.06 16.018-7.304 15.961-16.184-.057-8.477-6.68-15.364-15.007-15.909" fill="#18A5D6" />
        <path d="M36.655 47.502c.965.915 1.602 1.826 1.593 3.315-.008 2.147-1.823 3.892-4.048 3.901-2.123-.014-3.915-1.816-3.929-3.936-.01-1.732.992-2.694 1.53-3.21l.237-.23c.607-.586 1.087-1.053 1.143-1.797-5.587.545-9.943 5.254-9.902 10.98.039 6.066 4.988 10.948 11.052 10.906 6.065-.042 10.946-4.993 10.907-11.057-.037-5.677-4.386-10.324-9.918-10.855-.03-.003-.058-.003-.088-.004.058.661.749 1.375 1.423 1.987" fill="#18A5D6" />
        <path d="M36.655 47.502c.965.915 1.602 1.826 1.593 3.315-.008 2.147-1.823 3.892-4.048 3.901-2.123-.014-3.915-1.816-3.929-3.936-.01-1.732.992-2.694 1.53-3.21l.237-.23c.607-.586 1.087-1.053 1.143-1.797-5.587.545-9.943 5.254-9.902 10.98.039 6.066 4.988 10.948 11.052 10.906 6.065-.042 10.946-4.993 10.907-11.057-.037-5.677-4.386-10.324-9.918-10.855-.03-.003-.058-.003-.088-.004.058.661.749 1.375 1.423 1.987" fill="#004E69" />
        <path d="M86.682 45.52l.002.014c5.635.498 10.043 5.231 10.003 10.991-.039 6.066-4.989 10.948-11.053 10.906-6.065-.041-10.945-4.992-10.907-11.058.038-5.671 4.379-10.314 9.906-10.852-8.322.55-14.937 7.435-14.994 15.907-.058 8.88 7.084 16.124 15.96 16.184 8.872.06 16.117-7.086 16.177-15.964.057-8.584-6.63-15.62-15.094-16.128" fill="#F01446" />
        <path d="M88.104 47.502c.965.914 1.602 1.825 1.593 3.315-.009 2.147-1.824 3.892-4.048 3.902-2.123-.014-3.916-1.817-3.929-3.936-.01-1.733.992-2.694 1.53-3.21l.237-.232c.613-.591 1.098-1.061 1.145-1.82-5.526.537-9.867 5.181-9.904 10.853-.039 6.065 4.841 11.015 10.906 11.057 6.064.041 11.014-4.84 11.052-10.907.042-5.76-4.367-10.493-10.002-10.99.069.656.752 1.36 1.42 1.968" fill="#F01446" />
        <path d="M88.104 47.502c.965.914 1.602 1.825 1.593 3.315-.009 2.147-1.824 3.892-4.048 3.902-2.123-.014-3.916-1.817-3.929-3.936-.01-1.733.992-2.694 1.53-3.21l.237-.232c.613-.591 1.098-1.061 1.145-1.82-5.526.537-9.867 5.181-9.904 10.853-.039 6.065 4.841 11.015 10.906 11.057 6.064.041 11.014-4.84 11.052-10.907.042-5.76-4.367-10.493-10.002-10.99.069.656.752 1.36 1.42 1.968" fill="#8B0020" />
        <path d="M84.646 45.519h-.014v.001l.014-.001" fill="#FFF" />
      </g>
    </svg>
  </span>
  <span style={lightColoredContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" width="120px">
      <defs>
        <path id="a" d="M.414.713h134.764v134.764H.414z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 00-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932.262.261.697.261.96 0 2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266" fill="#FFF" />
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 00-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932.262.261.697.261.96 0 2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266m37.49-76.476a2.808 2.808 0 00-3.975 0l-4.615 4.614C114.023 13.975 92.463.713 67.796.713S21.57 13.975 9.827 33.75l-4.614-4.614a2.802 2.802 0 00-1.988-.823 2.806 2.806 0 00-2.81 2.811v36.97c0 37.215 30.167 67.383 67.381 67.383s67.382-30.168 67.382-67.382V31.124c0-.718-.275-1.439-.823-1.988" fill="#000" mask="url(#b)" />
        </g>
        <path d="M37.62 50.382c6.77.65 12.09 6.334 12.135 13.277.048 7.418-5.925 13.475-13.344 13.525-7.42.05-13.476-5.92-13.523-13.34-.05-7.004 5.28-12.764 12.115-13.43l.002-.022c-10.298.682-18.413 9.261-18.344 19.718.074 10.86 8.938 19.601 19.792 19.528 10.86-.074 19.599-8.934 19.528-19.796-.07-10.37-8.171-18.794-18.361-19.46" fill="#18A5D6" />
        <path d="M39.254 52.808c1.18 1.119 1.96 2.233 1.949 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.597-.017-4.791-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.872-3.926l.29-.282c.743-.716 1.328-1.288 1.397-2.199-6.834.667-12.165 6.428-12.114 13.43.047 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.046-6.945-5.366-12.628-12.135-13.279l-.107-.004c.07.809.916 1.68 1.74 2.431" fill="#18A5D6" />
        <path d="M39.254 52.808c1.18 1.119 1.96 2.233 1.949 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.597-.017-4.791-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.872-3.926l.29-.282c.743-.716 1.328-1.288 1.397-2.199-6.834.667-12.165 6.428-12.114 13.43.047 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.046-6.945-5.366-12.628-12.135-13.279l-.107-.004c.07.809.916 1.68 1.74 2.431" fill="#004E69" />
        <path d="M100.463 50.383l.002.018c6.895.608 12.289 6.397 12.239 13.443-.048 7.42-6.104 13.392-13.524 13.34-7.42-.05-13.391-6.107-13.344-13.525.046-6.937 5.357-12.616 12.119-13.275-10.182.673-18.275 9.095-18.345 19.458-.07 10.862 8.667 19.722 19.528 19.796 10.854.073 19.719-8.668 19.793-19.528.07-10.5-8.112-19.105-18.468-19.727" fill="#F01446" />
        <path d="M102.203 52.808c1.18 1.118 1.959 2.232 1.949 4.055-.01 2.625-2.231 4.76-4.953 4.772-2.598-.018-4.79-2.222-4.807-4.814-.011-2.12 1.213-3.296 1.871-3.927l.292-.283c.75-.724 1.342-1.299 1.4-2.227-6.761.658-12.073 6.338-12.119 13.275-.047 7.418 5.924 13.474 13.345 13.525 7.42.051 13.475-5.92 13.522-13.34.051-7.046-5.343-12.836-12.237-13.444.083.802.919 1.665 1.737 2.408" fill="#F01446" />
        <path d="M102.203 52.808c1.18 1.118 1.959 2.232 1.949 4.055-.01 2.625-2.231 4.76-4.953 4.772-2.598-.018-4.79-2.222-4.807-4.814-.011-2.12 1.213-3.296 1.871-3.927l.292-.283c.75-.724 1.342-1.299 1.4-2.227-6.761.658-12.073 6.338-12.119 13.275-.047 7.418 5.924 13.474 13.345 13.525 7.42.051 13.475-5.92 13.522-13.34.051-7.046-5.343-12.836-12.237-13.444.083.802.919 1.665 1.737 2.408" fill="#8B0020" />
        <path d="M97.972 50.382l-.017.001h.017" fill="#FFF" />
      </g>
    </svg>
  </span>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      